// @mui
import { Box, styled } from "@mui/material";

// ------------------------------------------------------------------------------------------------------------------------

const LoaderBox = styled(Box)({
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "rgba(255, 255, 255, 0.5)"
})

// ------------------------------------------------------------------------------------------------------------------------

export default function Loader() {
    return (
        <LoaderBox></LoaderBox>
    );
}