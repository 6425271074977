// react-router-dom
import { Outlet } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
// element
import {
    Header,
    Footer
} from "./element";

// ------------------------------------------------------------------------------------------------------------------------

export default function Layout() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header />
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
}
