// react-helemt
import { Helmet } from "react-helmet";
// react-router-dom
import { BrowserRouter } from 'react-router-dom';
// Scroll to top
// import ScrollToTop from "./components/Nav/ScrollToTop";
// Router
import Router from "./router/Router";

// ------------------------------------------------------------------------------------------------------------------------

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Gupter:wght@400;500;700&display=swap" rel="stylesheet" />
      </Helmet>
      <BrowserRouter>
        {/* <ScrollToTop /> */}
        <Router />
      </BrowserRouter>
    </>
  );
}

