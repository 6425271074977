const path = {
    landing: '/',
    about_conference: 'aboutConference',
    paper_submission: 'paperSubmission',
    program: 'program',
    registration: 'registration',
    about_macau: 'aboutMacau',
    bangkok2022: 'bangkok2022',
    bali2023: 'bali2023',
};

export default path;