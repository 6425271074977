import { Suspense, lazy } from "react";
// Component
import Loader from "../Loader";

// ------------------------------------------------------------------------------------------------------------------------

const Loadable = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
)

// ------------------------------------------------------------------------------------------------------------------------

// nav
export const Header = Loadable(lazy(() => import('../nav/Header/Header')));
export const Footer = Loadable(lazy(() => import('../nav/Footer')));

// pages
export const Landing = Loadable(lazy(() => import('../page/Landing/Landing')));
export const AboutConference = Loadable(lazy(() => import('../page/AboutConference/AboutConference')));
export const PaperSubmission = Loadable(lazy(() => import('../page/PaperSubmission/PaperSubmission')));
export const Program = Loadable(lazy(() => import('../page/Program/Program')));
export const Registration = Loadable(lazy(() => import('../page/Registration/Registration')));
export const AboutMacau = Loadable(lazy(() => import('../page/AboutMacau/AboutMacau')));
export const Bangkok2022 = Loadable(lazy(() => import('../page/PreviousConferences/Bangkok2022/Bangkok2022')));
export const Bali2023 = Loadable(lazy(() => import('../page/PreviousConferences/Bali2023/Bali2023')));