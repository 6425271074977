// react-router-dom
import { useRoutes } from "react-router-dom";
// paths
import path from "./path";
// layout
import Layout from "./Layout";
// pages
import {
    Landing,
    AboutConference,
    PaperSubmission,
    Program,
    Registration,
    AboutMacau,
    Bangkok2022,
    Bali2023,
} from "./element";

// ------------------------------------------------------------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            element: <Layout />,
            children: [
                { path: path.landing, element: <Landing /> },
                { path: path.about_conference, element: <AboutConference /> },
                { path: path.paper_submission, element: <PaperSubmission /> },
                { path: path.program, element: <Program /> },
                { path: path.registration, element: <Registration /> },
                { path: path.about_macau, element: <AboutMacau /> },
                { path: path.bangkok2022, element: <Bangkok2022 /> },
                { path: path.bali2023, element: <Bali2023 /> },
            ]
        },
    ])
}